import DateFnsUtils from '@date-io/date-fns';
import { Button, Card, CardActions, CardHeader, Collapse, Grid, List, ListSubheader, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloudUpload as UploadIcon, Done as YesIcon, Help as QuestionIcon, NotInterested as NoIcon } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardAttachment from '../Attachments/CardAttachment';
import { postFormData } from './../../../utils/ApiRequest';
import { responseAttachementPostUrl } from './../../../utils/ApiUrl';
import { uploadFileSize, uploadFileTypes } from './../../../utils/AppConstants';
import { GetToken } from './../../../utils/tokenActions';
import { AppContext } from './../../AuthenticatedPage';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        marginBottom: theme.spacing(1),
        '& .MuiCardActions-root': {
            marginRight: theme.spacing(5),
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    favButton: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    documentList: {
        width: '100%',
        maxWidth: '60ch',
        backgroundColor: theme.palette.background.paper,
    },
    attachmentHeader: {
        fontSize: theme.typography.pxToRem(20),
    },
    enabledYesText: {
        '& .MuiTypography-root': {
            color: theme.palette.primary.main,
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
        }
    },
    enabledNoText: {
        '& .MuiTypography-root': {
            color: theme.palette.warning.main,
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.warning.main,
        }
    },
    extendedInput: {
        '& .MuiInputBase-root': {
            marginRight: theme.spacing(2),
        },
        '& .MuiFormControl-root': {
            marginTop: 0,
        },
    }
}));

export default function RequestActionItem(props) {

    const [t] = useTranslation();
    const classes = useStyles();

    const appContext = useContext(AppContext);
    const { dispatch } = appContext;

    const { item, isRequestOnHold, actionTasks, setActionTasks } = props;
    const [investigationDate, setInvestigationDate] = useState(item.investigation_executed_on)
    const [remarks, setRemarks] = useState(item.remarks ? item.remarks: "")

    const [answer, setAnswer] = useState(item.actionResponse === null || item.actionResponse === undefined ? "" : item.actionResponse ? "no" : "yes");
    const [open, setOpen] = useState(item.actionResponse !== null && item.actionResponse !== undefined && item.actionResponse);
    const [openFileDialog, setOpenFileDialog] = useState(false);

    const handleAnswer = (event, newAnswer) => {

        if (!newAnswer) {
            return;
        }
        else if (answer === newAnswer) {
            setOpen(newAnswer === 'no' ? true : false);
            return;
        }
        
        updateProp("actionResponse", newAnswer === 'no');

        if (answer !== null) {
            setAnswer(newAnswer);
        }

        setOpen(newAnswer === 'no' ? true : false);
    };

    const updateProp = (prop, value) => {
        const arr = [...actionTasks];
        const currentItemIndex = arr.findIndex(a => a.id === item.id);
        if (currentItemIndex > -1) {
            const aa = arr[currentItemIndex];
            aa[prop] = value;
            arr.splice(currentItemIndex, 1, aa);
            setActionTasks(arr);
        }
    }

    const handleCloseFileDialog = () => {
        setOpenFileDialog(false)
    }

    const handleOpenFileDialog = () => {
        setOpenFileDialog(true)
    }

    const handleInvestigationDateChange = (date) => {
        updateProp("investigation_executed_on", date);
        setInvestigationDate(date);
    }

    const handleRemarksChange = (e) => {
        updateProp("remarks", e.target.value);
        setRemarks(e.target.value);
    }

    const handleUploadDocument = async (files) => {

        const data = new FormData();
        for (const file of files) {
            data.append('files', file);
          }

        //Get session token
        const apiURL = responseAttachementPostUrl(item.id);
        const response = await postFormData(apiURL, data, GetToken());
        const status = response.status;
        if (status === 200 || status === 201) {
  
            dispatch({ "type": "setSnackBarInfo", value: { message: `${t('generalFile')} '${files[0].path}' ${t('generalUploaded')}`, isError: false } })
            dispatch({ "type": "setOpenSnackbar", value: true })
        }
        else if (status === 401) {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('sessionExpired'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
        }
        else {
            dispatch({ "type": "setSnackBarInfo", value: { message: t('generalErrorUpload'), isError: true } })
            dispatch({ "type": "setOpenSnackbar", value: true })
        }

        setOpenFileDialog(false);
    }

    return (
        <div>
            <Card className={classes.root} elevation={4}>
                <CardHeader
                    avatar={<QuestionIcon color="primary" />}
                    title={<Typography variant="h6">{item.actionTaskItem.description}</Typography>}
                />
                <CardActions>
                    <Grid container>
                        <Grid item xs={12}>
                            <ToggleButtonGroup size="small" exclusive value={answer} onChange={handleAnswer} aria-label="device">
                                <ToggleButton value="yes" aria-label="yes"
                                    disabled={isRequestOnHold}
                                    className={clsx({ [classes.enabledYesText]: !isRequestOnHold })}
                                >
                                    <YesIcon/>
                                    <Typography>
                                        {t('generalYes')}
                                    </Typography>
                                </ToggleButton>
                                <ToggleButton value="no" aria-label="no"
                                    disabled={isRequestOnHold}
                                    className={clsx({ [classes.enabledNoText]: !isRequestOnHold })}>
                                    <NoIcon/>
                                    <Typography>
                                        {t('generalNo')}
                                    </Typography>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={12} className={classes.extendedInput}>
                            <Collapse in={open} style={{ margin: 0, padding: 0 }}>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        required
                                        fullWidth
                                        format="dd-MM-yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label={t('pageResponseExecDate')}
                                        value={investigationDate}
                                        onChange={(date) => handleInvestigationDateChange(date)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    <br />
                                </MuiPickersUtilsProvider>
                                <TextField
                                    margin="none"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="remarks"
                                    label={t('generalRemarks')}
                                    name="remarks"
                                    autoComplete="off"
                                    value={remarks}
                                    onChange={(e) => handleRemarksChange(e)}
                                />
                                <br />

                                {item.attachments && item.attachments.length > 0 ?
                                    <List dense={true} className={classes.documentList}
                                        subheader={
                                            <ListSubheader color="primary" className={classes.attachmentHeader} component="div" id="nested-list-subheader">
                                                {t('generalAttachments')}
                                            </ListSubheader>
                                        }>
                                        {item.attachments.map(attachment =>
                                            <CardAttachment key={attachment.id} attachment={attachment} isRequestAction={false}/>
                                        )}
                                    </List>:
                                    <div/>}

                                <Button variant="contained" color="primary" startIcon={<UploadIcon />} className={classes.favButton} onClick={handleOpenFileDialog}>
                                    {t('generalUploadNewDoc')}
                                </Button>
                                <DropzoneDialog
                                    dialogTitle={t('generalUploadDocument')}
                                    filesLimit={1}
                                    open={openFileDialog}
                                    onSave={handleUploadDocument}
                                    acceptedFiles={uploadFileTypes}
                                    maxFileSize={uploadFileSize}
                                    onClose={handleCloseFileDialog}
                                    cancelButtonText={t('generalCancel')}
                                    submitButtonText={t('generalSubmit')}
                                    dropzoneText={t('pageReqCreateDragDropFile')}
                                    showPreviews={true}
                                />
                            </Collapse>
                        </Grid>
                    </Grid>

                </CardActions>
            </Card>
        </div>
    )
}