import DateFnsUtils from "@date-io/date-fns";
import {
  CircularProgress,
  Fab,
  FormLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useQuery } from "@tanstack/react-query";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  attachmentType,
  downloadType,
  roleConfigs,
} from "../../../utils/AppConstants";
import {
  appConfigurations,
  appPages,
  dataFetchKeys,
  queryOptionsEnabled,
} from "../../../utils/configs";
import {
  getRequestListRoute,
  requestListType,
} from "../../../utils/routeConfigs";
import { AppContext } from "../../AuthenticatedPage";
import BackDrop from "../../Common/BackDrop";
import ComponentAttachments from "../Attachments/ComponentAttachments";
import { getData, postData } from "./../../../utils/ApiRequest";
import {
  checkListCategoriesForRequestUrl,
  requestAttachementDownloadUrl,
  requestAttachementGetUrl,
} from "./../../../utils/ApiUrl";
import { GetToken } from "./../../../utils/tokenActions";
import RequestCheckList from "./RequestCheckList";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(3),
    "& .MuiGrid-container": {
      "& h3": {
        marginBottom: theme.spacing(2),
      },
      "& .MuiGrid-item": {
        width: "40%",
      },
      "& .MuiTextField-root, .MuiAutocomplete-root": {
        marginBottom: theme.spacing(2),
      },
    },
    "& .MuiSwitch-root": {
      marginLeft: theme.spacing(2),
    },

    "& .Mui-disabled": {
      color: "grey",
    },
  },
  tooltipButton: {
    position: "fixed",
    marginTop: theme.spacing(0.5),
  },
  buttonsDiv: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(10),
  },
}));

export default function RequestDetails(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { state, dispatch } = appContext;
  const { requests, currentUser, userRoles, authToken } = state;
  const { request, isDialog } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const requestObject = requests.find((r) => r.id === request.id);
  let history = useHistory();
  const isAdmin = userRoles.some((r) => [roleConfigs.admin].includes(r.role));
  const isOwnerUser = requestObject?.createdBy?.id === currentUser?.id;
  const canEdit = isAdmin || isOwnerUser;
  const requestId = requestObject?.id || "";
  const isAmpApp = appConfigurations.isAmpApp;

  const { data: requestCheckList, isLoading: requestCheckListLoading } =
    useQuery(
      [`${dataFetchKeys.requestCheckList}${requestId}`],
      async () => {
        const url = checkListCategoriesForRequestUrl(requestId);
        const result = await getData(url, authToken);
        return await result.json();
      },
      queryOptionsEnabled(isAmpApp && requestId !== "" && authToken !== "")
    );

  const { data: requestAttachments, isLoading: attachmentsLoading } = useQuery(
    [`${dataFetchKeys.requestAttachments}${requestId}`],
    async () => {
      const url = requestAttachementGetUrl(requestId, attachmentType.create);
      const result = await getData(url, authToken);
      return await result.json();
    },
    queryOptionsEnabled(requestId !== "" && authToken !== "")
  );

  useEffect(() => {
    setAttachments(requestAttachments || []);
  }, [requestAttachments]);

  async function handleDownloadAttachment(fileId, fileName) {
    try {
      setIsBusy(true);
      const url = requestAttachementDownloadUrl(fileId);
      const response = await postData(url, null, GetToken());
      if (response.status >= 200 && response.status <= 300) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = url;
        anchor.target = "_blank";
        anchor.click();
      }
    } finally {
      setIsBusy(false);
    }
  }

  const handleGoBack = () => {
    dispatch({ type: "setSelectedPage", value: appPages.requests });
    history.push({
      pathname: getRequestListRoute(requestListType.all),
    });
  };

  const loading =
    (isAmpApp ? requestCheckListLoading : false) || attachmentsLoading;

  return loading ? (
    <BackDrop loading={loading} />
  ) : (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="center" direction="column">
        <Grid item>
          <TextField
            variant="outlined"
            fullWidth
            disabled
            label={t("generalRequestType")}
            value={requestObject?.requestType?.description || ""}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            fullWidth
            disabled
            label={t("generalReqNo")}
            value={requestObject?.request_number || ""}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            fullWidth
            disabled
            multiline
            label={t(isAmpApp ? "buildingNumber" : "generalDescription")}
            value={requestObject?.description || ""}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            fullWidth
            disabled
            label={t(isAmpApp ? "projectName" : "pageReqCreateRefNo")}
            value={requestObject.reference_number || ""}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            fullWidth
            disabled
            multiline
            label={t(isAmpApp ? "briefDescription" : "pageReqCreateMotivation")}
            value={requestObject?.motivation || ""}
          />
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item>
            <KeyboardDatePicker
              disabled
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              fullWidth
              format="dd-MM-yyyy"
              margin="none"
              label={t("pageReqCreateExpStartDate")}
              value={requestObject?.start_date || new Date()}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              disabled
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              fullWidth
              format="dd-MM-yyyy"
              margin="none"
              label={t("pageReqCreateExpEndDate")}
              value={requestObject?.end_date || new Date()}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item>
          <TextField
            variant="outlined"
            disabled
            fullWidth
            multiline
            label={t("generalRemarks")}
            name="remarks"
            value={requestObject?.remarks || ""}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              fullWidth
              format="dd-MM-yyyy"
              margin="none"
              label={t("generalCreatedOn")}
              value={requestObject?.created_on || new Date()}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            variant="outlined"
            disabled
            fullWidth
            multiline
            label={t("generalCreatedBy")}
            name="remarks"
            value={requestObject.createdBy?.fullName || ""}
          />
          <TextField
            variant="outlined"
            disabled
            fullWidth
            multiline
            label={t("pageUsersEmail")}
            name="remarks"
            value={requestObject.createdBy?.email || ""}
          />
          <TextField
            variant="outlined"
            disabled
            fullWidth
            multiline
            label={t("pageUsersCompany")}
            name="remarks"
            value={requestObject.createdBy?.company_name || ""}
          />
        </Grid>
        {!isAmpApp && (
          <div>
            <Grid>
              <Grid container justify="space-between" alignItems="center">
                <FormLabel component="legend">
                  {t("isChangesExpected")}
                </FormLabel>
                <FormControlLabel
                  label={
                    requestObject?.isChangesExpected
                      ? t("generalYes")
                      : "N.V.T."
                  }
                  control={
                    <Switch
                      disabled
                      color="primary"
                      checked={requestObject?.isChangesExpected || false}
                      name="isChangesExpected"
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid>
              <Grid container justify="space-between" alignItems="center">
                <FormLabel component="legend">{t("urgent")}</FormLabel>
                <FormControlLabel
                  label={requestObject?.isUrgent ? t("generalYes") : "N.V.T."}
                  control={
                    <Switch
                      disabled
                      color="primary"
                      checked={requestObject?.isUrgent || false}
                      name="isUrgent"
                    />
                  }
                />
              </Grid>
            </Grid>
          </div>
        )}
        {requestObject?.isUrgent && requestObject?.urgentDocument && (
          <Grid item>
            <Grid container>
              <Grid item xs={11}>
                <TextField
                  variant="outlined"
                  multiline
                  disabled
                  fullWidth
                  id="urgencyDocument"
                  label={t("urgencyDocument")}
                  name="urgencyDocument"
                  value={requestObject.urgentDocument.original_file_name}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title={t("downloadUrgencyDocument")}>
                  <IconButton
                    disabled={isBusy}
                    color="primary"
                    onClick={() =>
                      handleDownloadAttachment(
                        requestObject.urgentDocument.id,
                        requestObject.urgentDocument.original_file_name
                      )
                    }
                  >
                    <GetAppIcon />
                    {isBusy && (
                      <CircularProgress
                        color="primary"
                        size={20}
                        className={classes.progress}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}

        {isAmpApp || requestObject?.requestType?.is_planologic ? (
          <Fragment />
        ) : (
          <Fragment>
            <Grid item>
              <Grid container justify="space-between" alignItems="center">
                <FormLabel component="legend">
                  {t("planningAcceptance")}
                </FormLabel>
                <FormControlLabel
                  label={
                    requestObject?.hasPlanologischAcceptance
                      ? t("generalYes")
                      : "N.V.T."
                  }
                  control={
                    <Switch
                      disabled
                      color="primary"
                      checked={
                        requestObject?.hasPlanologischAcceptance || false
                      }
                      name="hasPlanologischAcceptance"
                    />
                  }
                />
              </Grid>
            </Grid>
            {!requestObject.hasPlanologischAcceptance ? (
              <Fragment />
            ) : (
              <Fragment>
                <Grid item>
                  <Grid container justify="space-between" alignItems="center">
                    <FormLabel component="legend">
                      {t("planningApproved")}
                    </FormLabel>
                    <FormControlLabel
                      label={
                        requestObject?.planologicApproved
                          ? t("generalYes")
                          : "N.V.T."
                      }
                      control={
                        <Switch
                          disabled
                          color="primary"
                          checked={requestObject?.planologicApproved || false}
                          name="planologicApproved"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Fragment>
            )}

            {!isAmpApp && requestObject && requestObject.planologicApproved && (
              <Grid item>
                <TextField
                  disabled
                  required
                  margin="none"
                  variant="outlined"
                  fullWidth
                  id="remarks"
                  label={t("planningApplicationNumber")}
                  name="remarks"
                  autoComplete="off"
                  value={requestObject.planologicRequestNumber}
                />
              </Grid>
            )}
          </Fragment>
        )}

        {isAmpApp && requestCheckList && requestCheckList.length > 0 && (
          <Grid item style={{ width: "100%" }}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <RequestCheckList
                disabled
                checkListCategories={requestCheckList}
              />
            </Grid>
          </Grid>
        )}

        <Grid item>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <ComponentAttachments
              attachments={attachments}
              setAttachments={setAttachments}
              uploadDisabled={true}
              typeDownload={downloadType.request}
              allowDelete={canEdit}
            />
          </Grid>
        </Grid>
      </Grid>
      {!isDialog && (
        <div className={classes.buttonsDiv}>
          <Fab
            variant="extended"
            color="default"
            aria-label="add"
            onClick={handleGoBack}
            className={classes.button}
          >
            <BackIcon className={classes.extendedIcon} /> {t("generalBack")}
          </Fab>
        </div>
      )}
    </div>
  );
}
